<template>
  <div>
    <div class="error white--text pa-2 font-italic" v-if="errMsg">
      {{ errMsg }}
    </div>

    <template v-else>
      <div v-if="isWait">Đang xử lý dữ liệu</div>
      <div v-else>
        <v-simple-table class="table-padding-2 table-h-36 mb-8">
          <template v-slot:default>
            <tbody>
              <tr class="text-center font-weight-medium">
                <td>Phân loại</td>
                <td>{{ $t("labels.quantity") }}</td>
                <td>Tổng doanh thu</td>
                <td>Doanh thu hàng hóa</td>
                <td>Thu nhập khác</td>
                <td style="width: 160px">Ngày</td>
              </tr>
              <tr class="text-center warning--text font-weight-medium">
                <td class="table-td-border-bottom">Đơn hàng</td>
                <td class="table-td-border-bottom">
                  {{ incomes.length }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumRevenueByOrders(incomes)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumGoodsRevenueByOrders(incomes)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumOtherRevenueByOrders(incomes)) }}
                </td>
                <td class="" rowspan="2">
                  <span class="fs-16px error--text">
                    {{ formatDateTime(summary.data_from, "DD/MM/YYYY") }}
                  </span>
                  <br />
                  <span class="fs-16px black--text">-</span> <br />
                  <span class="fs-16px error--text">
                    {{ formatDateTime(summary.data_to, "DD/MM/YYYY") }}
                  </span>
                </td>
              </tr>
              <tr class="text-center success--text font-weight-medium">
                <td class="table-td-border-bottom">Xuất hóa đơn</td>
                <td class="table-td-border-bottom">
                  {{ revenueOrders.length }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumRevenueByOrders(revenueOrders)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumGoodsRevenueByOrders(revenueOrders)) }}
                </td>
                <td class="table-td-border-bottom">
                  {{ formatNumber(sumOtherRevenueByOrders(revenueOrders)) }}
                </td>
              </tr>
              <!-- <tr class="text-center error--text font-weight-medium">
                <td class="table-td-border-bottom">Không xuất</td>
                <td class="table-td-border-bottom">
                  {{ noRevenueOrders.length }}
                </td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
              </tr> -->
              <tr class="text-center primary--text font-weight-medium">
                <td class="table-td-border-bottom">Điều chỉnh tự động</td>
                <td class="table-td-border-bottom">
                  {{ autoAdjustOrders.length }}
                </td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom" rowspan="2">
                  <v-btn
                    color="success"
                    @click="invoiceCreate"
                    :disabled="isDisabledBtn"
                  >
                    Xuất hóa đơn
                  </v-btn>
                </td>
              </tr>
              <tr class="text-center purple--text font-weight-medium">
                <td class="table-td-border-bottom">Cần xử lý</td>
                <td class="table-td-border-bottom">
                  {{ waitProcessOrders.length }}
                </td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
                <td class="table-td-border-bottom"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- <div class="mb-3 primary white--text pa-2">
          <div class="d-flex align-center justify-space-between w-100">
            <span>Danh mục xuất</span>
            <v-btn
              color="error"
              x-small
              v-if="noPriceItems.length > 0"
              @click="downloadNoPriceGoods"
            >
              <v-icon small class="mr-2">mdi-download</v-icon>
              Hàng hóa chưa có giá
            </v-btn>
          </div>
        </div>
        <v-simple-table class="table-padding-2 mb-8 table-h-30">
          <template v-slot:default>
            <tbody>
              <tr class="text-center font-weight-medium">
                <td>TT</td>
                <td>SKU</td>
                <td>{{ $t("labels.barcode") }}</td>
                <td>Tên</td>
                <td>Đặc tả</td>
                <td>Size</td>
                <td>Thực xuất</td>
                <td>Giá bán lẻ</td>
                <td class="success--text">
                  Thành tiền <br />
                  {{ formatNumber(summary.total_base_price) }}
                </td>
                <td class="success--text">
                  Số tiền chiết khấu <br />
                  {{ formatNumber(summary.total_discount_price) }}
                </td>
                <td class="success--text">
                  Giá trị hàng hóa <br />
                  {{ formatNumber(summary.total_goods_revenue) }}
                </td>
              </tr>
              <tr
                class="text-center"
                v-for="(item, index) in groupItems"
                :key="`g_${index}_${item.id}`"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.customer_goods_barcode }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.size }}</td>
                <td>{{ item.total_quantity }}</td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null ? formatNumber(item.price) : "CHƯA CÓ"
                  }}
                </td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null
                      ? formatNumber(item.base_price)
                      : "CHƯA CÓ"
                  }}
                </td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null
                      ? formatNumber(item.discount_price)
                      : "CHƯA CÓ"
                  }}
                </td>
                <td :class="{ 'error--text': item.price == null }">
                  {{
                    item.price != null
                      ? formatNumber(item.bill_invoice)
                      : "CHƯA CÓ"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->
      </div>
    </template>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";
import moment from "moment";

export default {
  name: "Shopee",
  components: {},
  props: {
    file: {},
    source: {
      type: Number,
      default: () => 0,
    },
    idShop: {
      type: Number,
      default: () => 0,
    },
    typeCreateExport: {
      type: Number,
      default: () => 0,
    },
    typeProductOrder: {
      type: Number,
      default: () => 0,
    },
    idCustomerInvoiceContract: {
      type: Number,
      default: () => 0,
    },
    idCustomerInvoiceContractTemplate: {
      type: Number,
      default: () => 0,
    },
    allowDate: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    summary: {},
    incomes: [],
    adjustments: [],
    waitProcessOrders: [],
    autoRequestOrders: [],
    incomeItems: [],
    autoAdjusts: {},
    errMsg: null,
    filePath: null,
    isWait: true,
  }),
  computed: {
    noPriceItems() {
      if (!this.incomeItems || this.incomeItems.length === 0) {
        return [];
      }
      return [...this.incomeItems].filter((item) => item.price == null);
    },
    revenueOrders() {
      return [...this.autoRequestOrders].filter(
        (io) => !io.id_customer_invoice_order
      );
    },
    noRevenueOrders() {
      return [...this.autoRequestOrders].filter((io) => io.revenue == 0);
    },
    autoAdjustOrders() {
      const orderProcessed = [...this.autoRequestOrders].filter(
        (io) => io.id_customer_invoice_order
      );
      const orderWait = [...this.waitProcessOrders].filter(
        (io) => io.id_customer_invoice_order
      );
      return [...orderProcessed, ...orderWait];
    },
    isDisabledBtn() {
      return this.noPriceItems.length > 0;
    },
    groupItems() {
      const groups = {};
      for (let i = 0; i < this.incomeItems.length; i++) {
        const item = this.incomeItems[i];
        if (item.revenue == 0) {
          continue;
        }
        if (!groups[item.id]) {
          groups[item.id] = {
            id: item.id,
            price: item.price,
            sku: item.sku,
            customer_goods_barcode: item.customer_goods_barcode,
            name: item.name,
            description: item.description,
            size: item.size,
            total_quantity: +item.total_quantity,
            revenue: +item.revenue,
            base_price: +item.base_price,
            discount_price: +item.discount_price,
            bill_invoice: +item.bill_invoice,
          };
        } else {
          groups[item.id].total_quantity += +item.total_quantity;
          groups[item.id].revenue += +item.revenue;
          groups[item.id].base_price += +item.base_price;
          groups[item.id].discount_price += +item.discount_price;
          groups[item.id].bill_invoice += +item.bill_invoice;
        }
      }
      return Object.values(groups);
    },
  },
  watch: {},
  mounted() {
    this.readFile();
  },
  methods: {
    async readFile() {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });

        const worksheetSummary = workbook.Sheets["Summary"];
        const summary = {
          data_from:
            (worksheetSummary["B10"] && worksheetSummary["B10"].v) || null,
          data_to:
            (worksheetSummary["B11"] && worksheetSummary["B11"].v) || null,
          base_price:
            (worksheetSummary["C16"] && worksheetSummary["C16"].v) || null,
          seller_subsidizes_price:
            (worksheetSummary["C17"] && worksheetSummary["C17"].v) || null,
          refund_to_buyer:
            (worksheetSummary["C18"] && worksheetSummary["C18"].v) || null,
          e_market_subsidizes_price:
            (worksheetSummary["C21"] && worksheetSummary["C21"].v) || null,
          discount_price:
            (worksheetSummary["C22"] && worksheetSummary["C22"].v) || null,
          seller_return_coin:
            (worksheetSummary["C23"] && worksheetSummary["C23"].v) || null,
          total_revenue:
            (worksheetSummary["D14"] && worksheetSummary["D14"].v) || null,
          total_order_revenue: 0,
        };

        let worksheetIncome = workbook.Sheets["Income"];
        const incomes = [];
        if (!worksheetIncome) {
          let index = 1;
          while (index) {
            worksheetIncome = workbook.Sheets[`Income - ${index}`];
            if (!worksheetIncome) {
              break;
            }
            const orders = this.getIncomeOrders(worksheetIncome);
            incomes.push(...orders);
            index++;
          }
        } else {
          const orders = this.getIncomeOrders(worksheetIncome);
          incomes.push(...orders);
        }

        let worksheetAdjustment = workbook.Sheets["Adjustment"];
        const adjustments = this.getAdjustments(worksheetAdjustment);

        this.isWait = false;
        this.processData({ summary, incomes, adjustments });
      };
      await reader.readAsArrayBuffer(this.file);
    },

    async processData({ summary, incomes, adjustments }) {
      this.errMsg = null;

      if (this.allowDate && summary.data_from != this.allowDate) {
        this.errMsg = `Chỉ cho phép xuất hóa đơn từ ngày ${this.allowDate}`;
        return false;
      }

      const today = moment().format("YYYY-MM-DD");
      if (summary.data_to >= today) {
        this.errMsg = `Danh sách income cần nhỏ hơn ngày hôm ngay`;
        return false;
      }

      const checkIncomes = [...incomes].filter(
        (od) =>
          od.payment_date < summary.data_from ||
          od.payment_date > summary.data_to
      );
      if (checkIncomes.length > 0) {
        const orderIds = [...checkIncomes].map((co) => co.customer_order_id);
        this.errMsg = `Danh sách income, đơn hàng ${orderIds.join(
          "; "
        )} không đúng ngày ${summary.data_from} - ${summary.data_to}`;
        return false;
      }

      const checkAdjustments = [...adjustments].filter(
        (od) =>
          od.adjustment_date < summary.data_from ||
          od.adjustment_date > summary.data_to
      );
      if (checkAdjustments.length > 0) {
        const orderIds = [...checkAdjustments].map(
          (co) => co.customer_order_id
        );
        this.errMsg = `Danh sách adjustment, đơn hàng ${orderIds.join(
          "; "
        )} không đúng ngày ${summary.data_from} - ${summary.data_to}`;
        return false;
      }

      this.summary = { ...summary };
      this.incomes = [...incomes];
      this.adjustments = [...adjustments];

      try {
        const { data } = await httpClient.post("/invoice-income-process", {
          id_customer_e_marketplace_shop: this.idShop,
          source: this.source,
          data_from: summary.data_from,
          data_to: summary.data_to,
          incomes,
          adjustments,
        });

        this.waitProcessOrders = [...data.waitProcessOrders];
        this.autoRequestOrders = [...data.autoRequestOrders];
        this.summary = {
          ...this.summary,
        };
        this.uploadFile();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.errMsg = errMsg;
      }
    },

    getIncomeOrders(worksheetIncome) {
      const mappingFields = {
        "Mã đơn hàng": "customer_order_id",
        "Order ID": "customer_order_id",

        "Mã yêu cầu hoàn tiền": "return_and_refund_code",
        "refund id": "return_and_refund_code",

        "Người Mua": "buyer",
        "Username (Buyer)": "buyer",

        "Ngày đặt hàng": "buy_date",
        "Order Creation Date": "buy_date",

        "Phương thức thanh toán của Người mua": "payment_method",
        "Buyer Payment Method": "payment_method",

        "Ngày hoàn thành thanh toán": "payment_date",
        "Payout Completed Date": "payment_date",

        "Giá gốc": "e_market_base_price",
        "Original product price": "e_market_base_price",

        "Số tiền bạn trợ giá cho sản phẩm": "seller_subsidizes_price",
        "Your Seller product promotion": "seller_subsidizes_price",

        "Số tiền hoàn trả cho Người mua (₫)": "refund_to_buyer",
        "Số tiền hoàn lại": "refund_to_buyer",
        "Refund Amount": "refund_to_buyer",

        "Sản phẩm được trợ giá từ Shopee": "e_market_subsidizes_price",
        "Rebate Provided by Shopee": "e_market_subsidizes_price",

        "Mã giảm giá": "discount_price",
        "Voucher Sponsored by Seller": "discount_price",

        "Người Bán Hoàn Xu": "seller_return_coin",
        "Coin Cashback Sponsored by Seller": "seller_return_coin",

        "Phí vận chuyển Người mua trả": "buyer_pay_delivery_fee",
        "Shipping Fee Paid by Buyer": "buyer_pay_delivery_fee",

        "Phí vận chuyển được trợ giá từ Shopee":
          "e_market_subsidizes_delivery_fee",
        "Shipping Rebate From Shopee": "e_market_subsidizes_delivery_fee",

        "Phí vận chuyển thực tế": "delivery_fee",
        "Actual Shipping Fee": "delivery_fee",

        "Phí trả hàng": "refund_fee",
        "Reverse Shipping Fee": "refund_fee",

        "Phí hoa hồng Tiếp thị liên kết": "affiliate_fee",
        "AMS Commission Fee": "affiliate_fee",

        "Phí cố định": "fixed_fee",
        "Commission fee": "fixed_fee",

        "Phí Dịch Vụ": "service_fee",
        "Service Fee": "service_fee",

        "Phí thanh toán": "payment_fee",
        "Transaction Fee": "payment_fee",

        "Tổng tiền đã thanh toán": "total_payment",
        "Total Released Amount (₫)": "total_payment",
        "Total Released Amount": "total_payment",

        "Mã voucher": "voucher_code",
        "Voucher Code From Seller": "voucher_code",

        "Đền bù đơn mất hàng": "compensation_for_lost_goods",
        "Lost Compensation": "compensation_for_lost_goods",

        "Phí vận chuyển - Người bán hỗ trợ": "seller_subsidizes_delivery_fee",
        "Shipping Fee Promotion by Seller": "seller_subsidizes_delivery_fee",

        "Đơn vị vận chuyển": "delivery_company",
        "Shipping provider": "delivery_company",

        // cxl
        "Giá sản phẩm (sau khuyến mãi)": "Giá sản phẩm (sau khuyến mãi)",
        "Shopee xu": "e_market_coin",
        "Shopee voucher": "e_market_voucher",
        "Ngân hàng khuyến mãi thanh toán trên Thẻ Tín Dụng":
          "Ngân hàng khuyến mãi thanh toán trên Thẻ Tín Dụng",
        "Shopee khuyến mãi thanh toán trên Thẻ Tín Dụng":
          "Shopee khuyến mãi thanh toán trên Thẻ Tín Dụng",
      };

      worksheetIncome["!ref"] = worksheetIncome["!ref"].replace("A1:", "A6:");
      const values = utils.sheet_to_json(worksheetIncome);

      const orders = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          item[mappingFields[i]] = `${v[i]}`.trim();
        });
        item.status = 1;
        item.revenue =
          +item.e_market_base_price +
          +item.seller_subsidizes_price +
          +(item.refund_to_buyer || 0) +
          +item.e_market_subsidizes_price +
          +item.discount_price +
          +item.seller_return_coin;
        item.goods_revenue =
          +item.e_market_base_price +
          +item.seller_subsidizes_price +
          +(item.refund_to_buyer || 0) +
          +item.discount_price +
          +item.seller_return_coin;
        item.other_revenue_delivery = 0;
        item.other_revenue = +item.e_market_subsidizes_price;
        return item;
      });

      return orders;
    },

    getAdjustments(worksheetAdjustment) {
      let adjustments = [];
      if (worksheetAdjustment) {
        const adjustmentMappingFields = {
          "Mã giao dịch": "stt",
          "Sequence No.": "stt",

          "Adjustment Complete Date": "adjustment_date",

          "Loại điều chỉnh | Mô tả": "type",
          "Adjustment Type | Description": "type",

          "Adjustment Reason": "reason",

          "Số tiền điều chỉnh": "adjustment_amount",
          "Adjustment Amount": "adjustment_amount",

          "Mã đơn hàng liên quan": "customer_order_id",
          "Linked Order No.": "customer_order_id",
        };
        worksheetAdjustment["!ref"] = worksheetAdjustment["!ref"].replace(
          "A1:",
          "A13:"
        );
        const adjustmentValues = utils.sheet_to_json(worksheetAdjustment);
        adjustments = [...adjustmentValues]
          .map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              item[adjustmentMappingFields[i]] = `${v[i]}`.trim();
            });
            return item;
          })
          .filter((item) => item.stt && item.adjustment_date && item.type);
      }
      return adjustments;
    },

    sumRevenueByOrders(orders) {
      let revenue = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        revenue += order.revenue;
      }
      return revenue;
    },

    sumGoodsRevenueByOrders(orders) {
      let revenue = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        revenue += order.goods_revenue;
      }
      return revenue;
    },

    sumOtherRevenueByOrders(orders) {
      let revenue = 0;
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        revenue += order.other_revenue;
      }
      return revenue;
    },

    async invoiceCreate() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-income-create", {
          source: 1,
          id_customer_invoice_contract: this.idCustomerInvoiceContract,
          id_customer_invoice_contract_template:
            this.idCustomerInvoiceContractTemplate,
          type_create_export: this.typeCreateExport,
          type_product_order: this.typeProductOrder,
          id_customer_e_marketplace_shop: this.idShop,
          data_from: this.summary.data_from,
          data_to: this.summary.data_to,
          incomes: this.incomes,
          adjustments: this.adjustments,
          file: this.filePath,
        });

        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("success");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async uploadFile() {
      let fd = new FormData();
      fd.append("file", this.file);
      const { data } = await httpClient
        .post(`/upload-image`, fd)
        .then()
        .catch();
      this.filePath = data;
    },

    downloadNoPriceGoods() {
      const ids = [];
      for (let i = 0; i < this.noPriceItems.length; i++) {
        const item = this.noPriceItems[i];
        if (!ids.includes(item.id)) {
          ids.push(item.id);
        }
      }
      this.$emit("downloadExcelNoPrice", ids);
    },
  },
};
</script>
